import React, { useRef } from "react";
import "./styles.css";
import sample from "./savefile.json";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Importing the Back icon

import EmailEditor from "react-email-editor";

const App = (props) => {
  const emailEditorRef = useRef(null);
  console.log("emailEditorRef 1", emailEditorRef);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      // Create a Blob containing the HTML content
      const blob = new Blob([html], { type: "text/html" });

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "email_template.html"; // Set the file name
      link.click();

      // Clean up by revoking the URL object
      URL.revokeObjectURL(url);

      console.log("emailEditorRef 2", emailEditorRef);
      console.log("exportHtml", html);
    });
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
      console.log("saveDesign", JSON.stringify(design, null, 4));
      alert("Design JSON has been logged in your developer console.");
    });
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const onLoad = () => {
    emailEditorRef.current.editor.addEventListener(
      "onDesignLoad",
      onDesignLoad
    );
    emailEditorRef.current.editor.loadDesign(sample);
  };

  const height = window.innerHeight;
  console.log(height);

  return (
    <div className="container">
      <div className="header">
        <div className="logo-container">
          <h1 className="header-text">
            <a href="#">MailSurge</a>
          </h1>
        </div>
        <div className="button-container">
          <button id="export-button" onClick={exportHtml}>
            Export HTML
          </button>
          {/* onClick={saveDesign} */}
          <button id="save-button">
            <a className="button-link" href="https://mailsurge.online/contact">
              Contact Us
            </a>
          </button>
        </div>
      </div>
      {/* onLoad={onLoad} */}

      <EmailEditor
        ref={emailEditorRef}
        className="email-editor"
        minHeight="100vh"
      />
    </div>
  );
};

export default App;
